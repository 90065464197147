import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from "../../ChatBot";

function normalizeString(str, campo) {
    if (str === undefined || str === null) {
        console.log('CAMPO COM PROBLEMA:'+campo);
        return '';
    }

    const inputString = String(str);  // Convertendo o campo em string
    return inputString.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
}

function BuscaGenerica() {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const { handleMouseOut, handleMouseOver, addMensagemTexto, addMensagemObjeto, tema, inputValue, setInputValue, removerUltimaMensagem } = useContext(AppContext);
    

    const escolherItem = (itemEscolhido) => {
        setSelectedItem(itemEscolhido);
        removerUltimaMensagem();
        addMensagemTexto("MsgEnvio", itemEscolhido?.[tema.camposMenuItem[0]]);
        addMensagemObjeto("MsgRetornoBuscaGenerica", itemEscolhido);
        setInputValue('');
    };

    useEffect(() => {
        if (tema && tema.baseDados) {
          import(`../base/${tema.baseDados}`)
            .then(jsonData => {
              setData(jsonData.default);
            })
            .catch(error => {
              console.error("Erro ao carregar o JSON", error);
            });
        }
      }, [tema]);

    useEffect(() => {
        setSelectedItem(null);
    }, [inputValue]);

    useEffect(() => {
        if(tema.camposPesquisa) {
                setFilteredData(
                    data
                        .map(item => {
                            const campos = [
                                ...tema.camposPesquisa
                            ];
                            
                            const terms = normalizeString(inputValue).split(' ').filter(Boolean);
                            
                            let score = 0;
                            
                            campos.forEach(campo => {
                                const normalizedCampo = item[campo] ? normalizeString(item[campo], campo) : '';
                                const termMatches = terms.filter(term => normalizedCampo.includes(term)).length;
                                // Se todos os termos estão presentes neste campo, adicione à pontuação
                                if(termMatches === terms.length) {
                                    score += 1;
                                    // Se o campo é uma correspondência exata, adicione um bônus à pontuação
                                    if(normalizedCampo === normalizeString(inputValue, campo)) score += 10;
                                }
                            });
                            
                            return {...item, score}; // Retorne o item junto com a sua pontuação
                        })
                        .filter(item => item.score > 0) // Mantenha apenas os itens que tenham pontuação maior que 0
                        .sort((a, b) => b.score - a.score) // Ordene os itens pela pontuação, do maior para o menor
                );

        }
    }, [inputValue, data, tema]);
    
    const getCombinedValues = (fields, item) => fields.map(field => item[field]).join(' - ');
    
    return (
        
        <div style={{ width: '100%' }}>
           
            {inputValue.length >= 1 && !selectedItem && (
                filteredData.length > 0 ? (

                <div style={{
                    maxHeight: '200px',
                    overflowY: 'scroll',
                    msOverflowStyle: 'none',
                    scrollbarWidth: 'none'
                }}>
                    <div >
                        {filteredData.map(item => (
                           
                            <button 
                            style={{
                                maxHeight: '100px',
                                overflowY: 'scroll',
                                msOverflowStyle: 'none',
                                scrollbarWidth: 'none',
                                width: '100%'
                            }}
                            onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}
                            onClick={() => escolherItem(item)}
                            type="button" 
                            alt={getCombinedValues(tema.camposTitle, item)}
                            title={getCombinedValues(tema.camposTitle, item)}
                            className="btn btn-outline-info" 
                            data-testid={getCombinedValues(tema.campoID, item)}
                            key={getCombinedValues(tema.campoID, item)}
                            data-id={getCombinedValues(tema.campoID, item)}
                            value={getCombinedValues(tema.camposMenuItem, item)}
                        >
                            {getCombinedValues(tema.camposMenuItem, item)}
                            </button>
                        ))}
                    </div>
                </div>
                ) : (
                    <div>
                         <button  
                            style={{
                                    maxHeight: '100px',
                                    overflowY: 'scroll',
                                    msOverflowStyle: 'none',
                                    scrollbarWidth: 'none',
                                    width: '100%'
                                }}
                            className="btn btn-outline-info" 
                            onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} >
                            Nenhum registro foi encontrado com o dado informado: <strong>{inputValue}</strong>
                        </button>
                    </div>
                )
            )}
        </div>
    );
}

export default BuscaGenerica;
