import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../ChatBot";

function Header() {

  const { handleMouseOut, handleMouseOver, dadosTribunal } = useContext(AppContext);
  const [selectedGif, setSelectedGif] = useState(null);

  useEffect(() => {
    const gifOptions = [dadosTribunal.ENDERECO_GIF1, dadosTribunal.ENDERECO_GIF2];
    const randomGif = gifOptions[Math.floor(Math.random() * gifOptions.length)];

    setSelectedGif(randomGif);
}, [dadosTribunal]);

  return (

    <div className="msg-header">
      
      <div className="msg-header-img">
      

          <a href={process.env.REACT_APP_CLIENT_URL+dadosTribunal.SIGLA_TRIBUNAL} 
            onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} 
            target="_blank" 
            rel="noopener noreferrer" 
            title="Clique aqui para abrir uma nova janela" >
            <img alt={dadosTribunal.MSG_BOAS_VINDAS_CURTA} className="msg-header-img"
              src={selectedGif}
              title={dadosTribunal.MSG_BOAS_VINDAS_CURTA} />
          </a>

      </div>

      <div className="active">
        <h4 onMouseOut={handleMouseOut}
          onMouseOver={handleMouseOver}
          onFocus={handleMouseOver}
          title={dadosTribunal.NOME_ROBO}>
          <img src={dadosTribunal.ENDERECO_NOME_ROBO} alt={dadosTribunal.NOME_ROBO} title={dadosTribunal.MSG_BOAS_VINDAS_CURTA} />

        </h4>
        <h6 onMouseOut={handleMouseOut}
          onMouseOver={handleMouseOver}
          onFocus={handleMouseOver}
          title={dadosTribunal.MENSAGEM_STATUS}>{dadosTribunal.MENSAGEM_STATUS}</h6>


      </div>

      <div className="logo">
        <h4 onMouseOut={handleMouseOut}
          onMouseOver={handleMouseOver}
          onFocus={handleMouseOver}
          title={dadosTribunal.DESCRICAO}>
          <img src={dadosTribunal.ENDERECO_LOGOTIPO_TRIBUNAL} alt={dadosTribunal.DESCRICAO} title={dadosTribunal.DESCRICAO} />

        </h4>

      </div>


    </div>

  );

}
export default Header;
