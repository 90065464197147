import React, { useState, useRef, createContext, useEffect} from "react";

import "./css/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import speech from './helpers/speechEvents';
import events from './helpers/events';
import utils from './helpers/utils';
import Header from './components/header';
import Body from './components/body';
import Footer from './components/footer';


export const AppContext = createContext(null);

function ChatBot(props) {
  
  const [mensagens, setMensagens] = useState([]);
  const [temas, setTemas] = useState({ temas: [] });
  const [tema, setTema] = useState(null);
  const [subTema, setSubTema] = useState(null);
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [ctrlPressionado, setCtrlPressionado] = useState(false);
  const [voiceRate, setVoiceRate] = useState(1.8);
  const [vozAtiva, setVozAtiva] = useState(false);
  const [classButtonVolume, setClassButtonVolume] = useState(process.env.REACT_APP_CLASS_BUTTON_VOLUME_INVISIBLE);
  const textInput = useRef(null);
  const [tribunal, setTribunal] = useState('');
  const [dadosTribunal, setDadosTribunal] = useState({});
  const [inputValue, setInputValue] = useState(""); // Adiciona o novo estado
  const [estiloSelecionado, setEstiloSelecionado] = useState("PEQUENO");

 
  const toggleEstilo = () => {
    const novoEstilo = estiloSelecionado === "PEQUENO" ? "GRANDE" : "PEQUENO";
    setEstiloSelecionado(novoEstilo);
  };

  useEffect(() => {
    setDadosTribunal(props.dados);
    setTribunal(props.dados.SIGLA_TRIBUNAL);
    setTemas(props.dados.ENDERECO_MENU_ORIENTACOES_GERAIS);
    setMensagens([{ tipo: "MsgInicio", msg: props.dados.MSG_BOAS_VINDAS, dataHoraMsg: utils.dataHoraAtual() }]);
  }, []);

  

  const addMensagemTexto = (tp, texto) => {
    mensagens.push({ tipo: tp, msg: texto, dataHoraMsg: utils.dataHoraAtual() });
    setMensagens([...mensagens]);
    speech.falar(texto, vozAtiva, voiceRate);
  };

  const addMensagemTextoSpinner = (tp, texto) => {
    mensagens.push({ tipo: tp, msg: texto, dataHoraMsg: utils.dataHoraAtual(), spinner: true });
    setMensagens([...mensagens]);
    speech.falar(texto, vozAtiva, voiceRate);
  };

  const addMensagemObjeto = (tp, objeto) => {
    mensagens.push({ tipo: tp, data: objeto, dataHoraMsg: utils.dataHoraAtual() });
    setMensagens([...mensagens]);
    speech.falar(objeto, vozAtiva, voiceRate);
  };

  const removerUltimaMensagem = () => {
    var ultimaMensagem = mensagens.pop();
    setMensagens([...mensagens]);
    return ultimaMensagem;
  };

  const handleMouseOver = speech.onMouseOver(vozAtiva, voiceRate);
  const handleMouseOut = speech.onMouseOut(vozAtiva);
  const handleVoice = speech.ativarDesativarVozAssistente(handleMouseOut, vozAtiva, setClassButtonVolume, voiceRate, setVozAtiva);
  const handleListenVoice = speech.ouvirVozUsuario(textInput);
  const handleClickDiminuir = speech.diminuirVolume(voiceRate, setVoiceRate);
  const handleClickAumentar = speech.aumentarVolume(voiceRate, setVoiceRate);
  const serviceDispatcher = events.obterRespostaAssistente(tribunal, tema, addMensagemObjeto, addMensagemTexto, subTema, setLoadingSpinner)
  const handleKeyPress = events.eventoOnKeyPress(addMensagemTexto, addMensagemObjeto, serviceDispatcher, addMensagemTextoSpinner, setInputValue, tema, removerUltimaMensagem);
  const handleKeyDown = events.eventoOnKeyDown(setCtrlPressionado, ctrlPressionado, vozAtiva, handleVoice);
  const handleSendButtonClick = events.enviarPergunta(textInput, addMensagemTexto, serviceDispatcher, addMensagemTextoSpinner, removerUltimaMensagem, addMensagemObjeto, tema);
  const handleTemasClick = events.escolherTema(tribunal, setTema, setSubTema, addMensagemTexto, tema, addMensagemObjeto, setMensagens, mensagens, textInput);
  const handlePredicoesComDuvidaSinapsesClick = events.escolherPredicao(addMensagemTexto, serviceDispatcher, textInput);
  const handleEncerramentoClick = events.handleEncerramentoClick(dadosTribunal, setMensagens, mensagens, tema, textInput);
  
  return (

    <AppContext.Provider value={
      {
        mensagens, temas, tema, setTema, setLoadingSpinner, loadingSpinner, setSubTema, handleMouseOut, handleMouseOver, handleKeyPress, handleKeyDown,
        handleListenVoice, handleTemasClick, handlePredicoesComDuvidaSinapsesClick, handleEncerramentoClick,
        handleVoice, handleClickDiminuir, handleClickAumentar, classButtonVolume, handleSendButtonClick, textInput,
        dadosTribunal, addMensagemTexto, addMensagemTextoSpinner, addMensagemObjeto, inputValue, setInputValue , removerUltimaMensagem, estiloSelecionado, toggleEstilo
      }
    }>
      <link rel="stylesheet" type="text/css" href={dadosTribunal.LINK_CSS} />
      <link
            rel="stylesheet"
            type="text/css"
            href={
              estiloSelecionado === "GRANDE"
                ? dadosTribunal.LINK_CSSGRANDE
                : dadosTribunal.LINK_CSSPEQUENO
            }
          />


      <div className="container_visible"
        style={
          { 
            backgroundImage: `url(${dadosTribunal.ENDERECO_PLANO_DE_FUNDO_TRIBUNAL})` ,
            backgroundSize: '100% 100%'
          }
          }>
        <Header />
        <Body />
        <Footer />
        

      </div>
    </AppContext.Provider>
  );

  
}
export default ChatBot;




