import React, { useContext } from "react";
import { AppContext } from "../../ChatBot";

function MsgInicio(props) {
	const { handleMouseOut, handleTemasClick, handleMouseOver, temas } = useContext(AppContext);
	
	if (props.dadosMensagem.msg !== '') {
		return (
			<div className="received-chats">
				<div className="received-msg-inbox">
					<div className="received-msg-inbox">

						<p title={props.dadosMensagem.msg} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>{props.dadosMensagem.msg}</p>
						<br />
						<div className="btn-group-vertical">
							{
							temas.temas.map((tema, index)=>
									(<button type="button" alt={tema.tema} title={tema.tema} 
										className="btn btn-outline-info" onMouseOut={handleMouseOut} 
										onMouseOver={handleMouseOver} onFocus={handleMouseOver} data-testid={tema.tema}
										onClick={(e) => {handleTemasClick(tema, 'tema', e)}} key={index} data-id={tema.tema}
										value={tema.tema}>{tema.descricao}</button>)
							)
							
							}
						</div>
						<span className="time" title={props.dadosMensagem.dataHoraMsg} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>{props.dadosMensagem.dataHoraMsg}</span>

					</div>
				</div>

			</div>
		)
	} else {
		return (<br />);
	}

}
export default MsgInicio;
