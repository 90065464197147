import React  from "react";
import ChatBot from "./ChatBot";
import { useState, useEffect } from "react";
import api from "./api/api";


function App() {

  const [tribunal, setTribunal] = useState('');
  const [dadosTribunal, setDadosTribunal] = useState({});
  
  useEffect(() => {
    let contextPath = window.location.pathname.split('/')[1]
    
    if (contextPath) {
        if(tribunal!==contextPath.toLowerCase()){
          setTribunal(contextPath.toLowerCase());
        }
     }else{
        setTribunal('tjba');
     }
     if(tribunal){
      api.obterDadosTribunal(tribunal).then(dados => {
          console.log(dados)
          setDadosTribunal(dados);
      }).catch((error) => {
          console.log(error);
      });
    }

  }, [tribunal]);

  return (dadosTribunal.SIGLA_TRIBUNAL?
            <div className="App"><ChatBot dados={dadosTribunal}/></div>:
            <div className="App"><img src={process.env.REACT_APP_CLIENT_URL + "loading6.gif"} alt="Carregando" title="Corregando" width="3%" height="3%" /> Carregando...</div>
  );
}

export default App;
