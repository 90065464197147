import { format } from 'date-fns';

function obterDataAtualFormatada(){
    
    return format(new Date(), 'dd/MM/yyyy hh:mm:ss');
}

function obterAvatar(ENDERECO_GIF1, ENDERECO_GIF2){
    
    return (new Date().getDay() % 2 === 0) ? process.env.ENDERECO_GIF1 : process.env.ENDERECO_GIF2;
}

const utils = {
    dataHoraAtual: obterDataAtualFormatada,
    obterAvatar: obterAvatar
}


export default utils;