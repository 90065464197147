import React, { useContext } from "react";
import { AppContext } from "../../ChatBot";

function MsgRetornoOrientacao(props) {

  const { handleMouseOut, handleMouseOver, temas, handleTemasClick, handlePredicoesComDuvidaSinapsesClick, handleEncerramentoClick, dadosTribunal } = useContext(AppContext);

  
  if (props.dadosMensagem.data) {

    if (props.dadosMensagem.data.subTema === 'Saudações') {
      return (
        <div className="received-chats">
          <div className="received-msg">
            <div className="received-msg-inbox">

              <p title={process.env.REACT_APP_MSG_NAO_ENTENDI + process.env.REACT_APP_MSG_REFAZER_PERGUNTA} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>{process.env.REACT_APP_MSG_NAO_ENTENDI + process.env.REACT_APP_MSG_REFAZER_PERGUNTA}</p>
              <br />
              <div className="btn-group-vertical">
                {
                  temas.temas.map((tema, index) =>
                  (<button type="button" alt={tema.tema} title={tema.tema}
                    className="btn btn-outline-info" onMouseOut={handleMouseOut}
                    onMouseOver={handleMouseOver} onFocus={handleMouseOver}
                    onClick={(e) => {handleTemasClick(tema, 'tema', e)}} key={index} data-id={tema.tema}
                    value={tema.tema}>{tema.tema}</button>)
                  )

                }
              </div>
              <span className="time" title={props.dadosMensagem.dataHoraMsg} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>{props.dadosMensagem.dataHoraMsg}</span>


            </div>
          </div>
        </div>
      )


    } else {

      if (parseFloat(props.dadosMensagem.data.acuracia) >= parseFloat(dadosTribunal.ACURACIA)) {
        
        let link = (props.dadosMensagem.data && props.dadosMensagem.data.link) ?
        <a href={props.dadosMensagem.data.link} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}  onFocus={handleMouseOver}
          target="_blank" rel="noopener noreferrer"
          title="Clique aqui para acessar o conteúdo em uma nova janela" >
          <img src={process.env.REACT_APP_CLIENT_URL + process.env.REACT_APP_IMAGEM_CLIQUE_AQUI}
            alt="Clique Aqui" title="Link descritivo" /></a> : null


        return (
          <div className="received-chats">
            <div className="received-msg">
              <div className="received-msg-inbox">
                <p title={props.dadosMensagem.data.textoPredicao} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                  {props.dadosMensagem.data.textoPredicao}
                </p>

                {link}
                <br />
                <p title={process.env.REACT_APP_MSG_SANAR_DUVIDA} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                  {process.env.REACT_APP_MSG_SANAR_DUVIDA}
                </p>
                <br />
                <button
                  type="button" className="btn btn-outline-info" title="Sim" key="encerramentoSim"
                  onClick={handleEncerramentoClick}
                  onMouseOut={handleMouseOut}
                  onMouseOver={handleMouseOver}
                  onFocus={handleMouseOver}  data-testid="Sim"
                  data-id="Sim" value="Sim">Sim</button>

                <button
                  type="button" className="btn btn-outline-info" title="Não" key="encerramentoNao"
                  onClick={handleEncerramentoClick}
                  onMouseOut={handleMouseOut}
                  onMouseOver={handleMouseOver}
                  onFocus={handleMouseOver}  data-testid="Não"
                  data-id="Não" value="Não">Não</button>


                <span className="time" title={props.dadosMensagem.dataHoraMsg} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>{props.dadosMensagem.dataHoraMsg}</span>
              </div>
            </div>
          </div>
        )

      } else {

        let predicoes = props.dadosMensagem.data.predicoes.filter(obj => obj!=='Saudações');

        return (
          <div className="received-chats">
            <div className="received-msg">
              <div className="received-msg-inbox">

                <p title={process.env.REACT_APP_MSG_DUVIDA} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>{process.env.REACT_APP_MSG_DUVIDA}</p>
                <br />
                <div className="btn-group-vertical">
                  {
                    predicoes.map((predicao, index) =>
                    (
                      
                      
                      <button type="button"
                        alt={predicao} title={predicao} className="btn btn-outline-info"
                        onClick={handlePredicoesComDuvidaSinapsesClick}
                        onMouseOut={handleMouseOut} onMouseOver={handleMouseOver}
                        onFocus={handleMouseOver} key={predicao} data-testid={predicao}
                        data-id={predicao} value={predicao}>{predicao}</button>
                        
                    )
                    )
                  }
                </div>
                <span className="time" title={props.dadosMensagem.dataHoraMsg} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>{props.dadosMensagem.dataHoraMsg}</span>
              </div>
            </div>
          </div>
        )

      }

    }


  } else {
    return <br />
  }


}
export default MsgRetornoOrientacao;
