import React, { useContext } from "react";
import { AppContext } from "../ChatBot";

function Footer() {
  const { handleMouseOut, handleMouseOver, handleKeyPress,
    handleKeyDown, handleListenVoice, handleSendButtonClick, textInput, loadingSpinner,  inputValue, setInputValue } = useContext(AppContext);

  return (

    <div className="msg-inbox">

      <div className="msg-bottom" onDoubleClick={handleListenVoice}>
        <div className="input-group">
       
          <input id="textoMsg" className="form-control" data-testid="textoMsg"
            title={process.env.REACT_APP_MSG_SOFIA_RECURSO_VOZ} 
            onMouseOut={handleMouseOut} onMouseOver={handleMouseOver} onKeyPress={handleKeyPress}
            onKeyDown={handleKeyDown} 
            disabled={loadingSpinner}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            ref={textInput} placeholder="Digite sua pergunta ..."
          />

          <div className="input-group-append">
            <span className="input-group-text">
              <i className="fa fa-paper-plane"  title={process.env.REACT_APP_MSG_ENVIAR_MENSAGEM}
                onClick={handleSendButtonClick} onMouseOut={handleMouseOut}  disabled={loadingSpinner}
                onMouseOver={handleMouseOver}  onKeyPress={handleKeyPress} data-testid="sendButton"></i>
              <i className="fa fa-microphone"  title={process.env.REACT_APP_MSG_COMECAR_A_FALAR}
                onClick={handleListenVoice}    onMouseOut={handleMouseOut}  disabled={loadingSpinner}
                onMouseOver={handleMouseOver}  onKeyPress={handleKeyPress}
              ></i>
            </span>
          </div>
          
        </div>
      </div>
      
    </div>
  );

}
export default Footer;
