import React, { useContext } from "react";
import { AppContext } from "../../ChatBot";

function MsgEnvio(props) {
  const { handleMouseOut, handleMouseOver } = useContext(AppContext);
  
  if (props.dadosMensagem.msg !== '') {
    return (
      <div className="outgoing-chats">
        <div className="outgoing-chats-msg">
          <p title={props.dadosMensagem.msg} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>{props.dadosMensagem.msg} </p>
          <span className="time" title={props.dadosMensagem.dataHoraMsg} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>{props.dadosMensagem.dataHoraMsg}</span>
        </div>
      </div>
    );
  } else {
    return (<br />);
  }

}
export default MsgEnvio;
