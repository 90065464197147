const isFirefox = typeof InstallTrigger !== 'undefined';

function Teste() { }

let isRecording = false;
const SPEECH = window.speechRecognition || window.webkitSpeechRecognition || window.SpeechSynthesisUtterance || Teste;
const recorder = new SPEECH();

recorder.onresult = (event) => {
    const resultIndex = event.resultIndex;
    const transcript = event.results[resultIndex][0].transcript;
    var element = document.getElementById("textoMsg");
    element.value = transcript;

};

recorder.onstart = function () {
    isRecording = true;
};

recorder.onend = function () {
    isRecording = false;
};

recorder.onerror = function (event) {
    isRecording = false;
};

const assistenteOuvir = () => {

    if (!isFirefox) {
        if (!isRecording) {

            try {
                recorder.start();
            } catch (error) {
                console.error(error);
            }
        }
    }

}

const assistenteSilenciar = (vozAtiva) => {

    if (!isFirefox) {
        if (vozAtiva) {
            window.speechSynthesis.cancel();
        }
    }
}

const assistenteFalar = (mensagem, vozAtiva, rate) => {
   
    if (!isFirefox) {
        if (vozAtiva) {
            
            var synth = window.speechSynthesis;
            var voiceOptions = synth.getVoices();

            const speech = new SpeechSynthesisUtterance();
            speech.text = mensagem;
            speech.volume = 5;
            speech.rate = rate;
            speech.pitch = 1;

            var falou = false;
            for (var i = 0; i < voiceOptions.length; i++) {
                if (voiceOptions[i].lang === process.env.REACT_APP_LINGUA_VOZ && voiceOptions[i].name.includes(process.env.REACT_APP_VOZ)) {

                    speech.voice = voiceOptions[i];
                    window.speechSynthesis.speak(speech);
                    falou = true;
                    break;
                }
            }
            
            if (!falou) {
                window.speechSynthesis.speak(speech);
            }
        } else {
           // window.speechSynthesis.cancel();
        }
    }

}

function onMouseOver(vozAtiva, voiceRate) {
    return (e) => {
      speech.falar(e.currentTarget.title, vozAtiva, voiceRate);
    };
  }
  
  function onMouseOut(vozAtiva) {
    return (e) => {
      speech.silenciar(vozAtiva);
    };
  }
  
  function ativarDesativarVozAssistente(handleMouseOut, vozAtiva, setClassButtonVolume, voiceRate, setVozAtiva) {
    return (e) => {
  
      handleMouseOut();
  
      if (vozAtiva) {
  
        setClassButtonVolume(process.env.REACT_APP_CLASS_BUTTON_VOLUME_INVISIBLE);
        speech.falar(process.env.REACT_APP_MSG_INATIVAR_RECURSO_VOZ, true, voiceRate);
        setVozAtiva(false);
  
      } else {
  
        setClassButtonVolume(process.env.REACT_APP_CLASS_BUTTON_VOLUME_VISIBLE);
        speech.falar(process.env.REACT_APP_MSG_ATIVAR_RECURSO_VOZ, true, voiceRate);
        setVozAtiva(true);
  
      }
  
    };
  }
  
  function ouvirVozUsuario(textInput) {
    return (e) => {
      speech.ouvir();
      textInput.current.focus();
    };
  }
  
  
  
  function diminuirVolume(voiceRate, setVoiceRate) {
    return (e) => {
      var aux = voiceRate;
  
      if (aux > 0) {
        aux = aux - (0.1);
        setVoiceRate(aux);
      }
  
    };
  }
  
  function aumentarVolume(voiceRate, setVoiceRate) {
    return (e) => {
      var aux = voiceRate;
      if (aux < 3) {
        aux = aux + (0.1);
        setVoiceRate(aux);
      }
    };
  }

const speech = {
    falar: assistenteFalar,
    silenciar: assistenteSilenciar,
    ouvir: assistenteOuvir,
    aumentarVolume:aumentarVolume,
    diminuirVolume:diminuirVolume,
    ouvirVozUsuario:ouvirVozUsuario,
    ativarDesativarVozAssistente:ativarDesativarVozAssistente,
    onMouseOut:onMouseOut,
    onMouseOver:onMouseOver
}

export default speech;
