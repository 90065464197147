import React from "react";
import BuscaGenerica from './BuscaGenerica';

function MsgBuscaGenerica() {
	
		return (
			<div className="received-chats">
				<div className="received-msg-inbox">
        		<BuscaGenerica/>
				</div>

			</div>
		)
	

}
export default MsgBuscaGenerica;
