import React, { useContext } from "react";
import { AppContext } from "../../ChatBot";


function MsgRetornoBuscaGenerica(props) {

  const { handleMouseOut, handleTemasClick, handleMouseOver, loadingSpinner, handleEncerramentoClick, tema} = useContext(AppContext);
  let link = (props.dadosMensagem.data && tema.camposLink && props.dadosMensagem.data[tema.camposLink[0]]) ?
    
    <a href={props.dadosMensagem.data[tema.camposLink[0]]} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}  onFocus={handleMouseOver}
        target="_blank" rel="noopener noreferrer"
        title="Clique aqui para acessar o sistema" >
        <img src={process.env.REACT_APP_CLIENT_URL + process.env.REACT_APP_IMAGEM_CLIQUE_AQUI}
          alt={`${props.dadosMensagem.data[tema.camposTitle[0]]}`}
          title={`${props.dadosMensagem.data[tema.camposTitle[0]]}`}
          /></a>: null


  if (props.dadosMensagem.data) {
    return (
      <div className="received-chats">
        <div className="received-msg">
          <div className="received-msg-inbox">
                  
                    {tema.camposResposta && tema.camposResposta.map((campo, index) => {
                        const valor = props.dadosMensagem.data[campo];
                        
                        let resposta = <></>

                        if(tema.subTemas && tema.subTemas[0].camposSubtemas && props.dadosMensagem.data[tema.camposSubtemas[0]]===tema.subTemas[0].camposSubtemas[0]){
                          resposta = <> <br/>
                                    <p title={tema.subTemas[0].mensagemIntrodutoria} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                                        {tema.subTemas[0].mensagemIntrodutoria}
                                    </p>
                                    <br />
                                    <button
                                      type="button" className="btn btn-outline-info" title={tema.subTemas[0].tema + ': ' + tema.subTemas[0].descricao} key="ConsultarBairrosSalvador"
                                      onClick={(e) => {handleTemasClick(tema.subTemas[0], 'tema', e)}}
                                      onMouseOut={handleMouseOut}
                                      onMouseOver={handleMouseOver}
                                      onFocus={handleMouseOver}  data-testid="ConsultarBairrosSalvador"
                                      data-id="ConsultarBairrosSalvador" value="ConsultarBairrosSalvador">{tema.subTemas[0].tema}</button></>
                        }

                        // Verifique se o valor é diferente de vazio, "-" ou "_"
                        if (valor && valor !== '-' && valor !== '_') {
                            return (
                              <div key={index}>
                                <p title={campo+ " " + valor} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                                    <b>{campo}</b><br />
                                    {valor}
                                </p>
                                <br/>
                                {link}
                                <br/>
                                {resposta}
                            </div>
                            );

                        } else {
                            return null;  // Retornar null se a condição não for atendida
                        }
                    })}

                   
<br />
                <p title={process.env.REACT_APP_MSG_SANAR_DUVIDA} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                  {process.env.REACT_APP_MSG_SANAR_DUVIDA}
                </p>
                <br />
                <button
                  type="button" className="btn btn-outline-info" title="Sim" key="encerramentoSim"
                  onClick={handleEncerramentoClick}
                  onMouseOut={handleMouseOut}
                  onMouseOver={handleMouseOver}
                  onFocus={handleMouseOver}  data-testid="Sim"
                  data-id="Sim" value="Sim">Sim</button>

                <button
                  type="button" className="btn btn-outline-info" title="Não" key="encerramentoNao"
                  onClick={handleEncerramentoClick}
                  onMouseOut={handleMouseOut}
                  onMouseOver={handleMouseOver}
                  onFocus={handleMouseOver}  data-testid="Não"
                  data-id="Não" value="Não">Não</button>


            <span className="time" title={props.dadosMensagem.dataHoraMsg} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
              {props.dadosMensagem.dataHoraMsg} 
            </span>
            <span className="spinner-img">
              {(loadingSpinner && props.spinner)?<img src={process.env.REACT_APP_CLIENT_URL + "loading6.gif"} alt="Carregando" title="Corregando" width="30%" height="30%" />:<br/>}
            </span>
          </div>


          
        </div>
      </div>

    )
  } else
  {
    return (<br />);
  }

}
export default MsgRetornoBuscaGenerica;
