import React, { useContext, useEffect } from "react";
import { AppContext } from "../ChatBot";
import MsgInicio from './mensagens/msgInicio';
import MsgEnvio from './mensagens/msgEnvio';
import MsgRetorno from './mensagens/msgRetorno';
import MsgRetornoOrientacao from './mensagens/msgRetornoOrientacao'
import MsgListarTemas from './mensagens/msgListarTemas'
import MsgBuscaGenerica from "./mensagens/msgBuscaGenerica";
import MsgRetornoBuscaGenerica from "./mensagens/msgRetornoBuscaGenerica";



function Body() {

  const { mensagens, handleVoice, handleClickDiminuir, handleClickAumentar, handleMouseOut,
    handleMouseOver, classButtonVolume} = useContext(AppContext);

  useEffect(() => {
    const divFimMensagens = document.getElementById('divFimMensagens');
    const timeOutId = setTimeout(() => divFimMensagens.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"}), 1000);
    


    return () => clearTimeout(timeOutId);
  });


  return (
    <div className="msg-inbox">


      <div className="volumeButtons">
      
       
        <button
          type="button" className={classButtonVolume} title="Diminuir Velocidade da Fala"
          onClick={handleClickDiminuir} onMouseOut={handleMouseOut} onMouseOver={handleMouseOver}
          onFocus={handleMouseOver} key="btn-" data-id="-" >
          <i className="fa fa-volume-down"></i>

        </button>
        <button
          type="button" className={classButtonVolume} title="Aumentar Velocidade da Fala"
          onClick={handleClickAumentar} onMouseOut={handleMouseOut} onMouseOver={handleMouseOver}
          onFocus={handleMouseOver} key="btn+" data-id="+" >
          <i className="fa fa-volume-up"></i>

        </button>
      </div>
      <div className="chats" onDoubleClick={handleVoice} title="Dê um duplo clique para ativar o recurso de voz">
        <div className="msg-page" id="divMensagens" data-testid="divMensagens">

          {
            mensagens.map((mensagem, index) =>
              (mensagem.tipo === "MsgInicio") ? (<MsgInicio key={index} dadosMensagem={mensagem} />) :
                (mensagem.tipo === "MsgEnvio") ? (<MsgEnvio key={index} dadosMensagem={mensagem} />) :
                  (mensagem.tipo === "MsgRetorno") ? (<MsgRetorno key={index} dadosMensagem={mensagem} spinner={mensagem.spinner} />) :
                    (mensagem.tipo === "MsgRetornoOrientacao") ? (<MsgRetornoOrientacao key={index} dadosMensagem={mensagem} />) :
                      (mensagem.tipo === "MsgListarTemas") ? (<MsgListarTemas key={index} dadosMensagem={mensagem} />) :
                        (mensagem.tipo === "MsgBuscaGenerica") ? (<MsgBuscaGenerica key={index} dadosMensagem={mensagem} />) :
                          (mensagem.tipo === "MsgRetornoBuscaGenerica") ? (<MsgRetornoBuscaGenerica key={index} dadosMensagem={mensagem} />) :
                            (<MsgInicio key={index} dadosMensagem={mensagem} />)
            )
          }

          <div id="divFimMensagens"></div>
          <br /><br />
        </div>
      </div>

    </div>

  );

}
export default Body;