import React, { useContext } from "react";
import { AppContext } from "../../ChatBot";


function MsgRetorno(props) {


  const { handleMouseOut, handleMouseOver, setSubTema, tema, handleTemasClick, loadingSpinner } = useContext(AppContext);
  
  if (props.dadosMensagem.msg) {
    return (
      <div className="received-chats">
        <div className="received-msg">
          <div className="received-msg-inbox">
            <p title={props.dadosMensagem.msg} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}> 
                  {props.dadosMensagem.msg} 
            </p>
            <span className="time" title={props.dadosMensagem.dataHoraMsg} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
              {props.dadosMensagem.dataHoraMsg} 
            </span>
            <span className="spinner-img">
              {(loadingSpinner && props.spinner)?<img src={process.env.REACT_APP_CLIENT_URL + "loading6.gif"} alt="Carregando" title="Corregando" width="30%" height="30%" />:<br/>}
            </span>
          </div>
        </div>
      </div>

    )
  } else
  if(tema.subTemas){
    
    return (
      <div className="received-chats">
        <div className="received-msg">
          <div className="received-msg-inbox">
           <br/>
          	<div className="btn-group-horizontal">
							{
                  tema.subTemas.map((subTema, index)=>
                      (<button type="button" alt={subTema.tema} title={subTema.tema} 
                        className="btn btn-outline-info" onMouseOut={handleMouseOut} 
                        onMouseOver={handleMouseOver} onFocus={handleMouseOver} data-testid={subTema.tema}
                        onClick={(e) => {handleTemasClick(subTema, setSubTema, e)}} key={index} data-id={subTema.tema}
                        value={subTema.tema}>{subTema.tema}</button>)
                  )
							
							}
              
						</div>
            
            <br/>
            <button type="button" alt="Voltar" title="Voltar"
                        className="btn btn-outline-info" onMouseOut={handleMouseOut} 
                        onMouseOver={handleMouseOver} onFocus={handleMouseOver} data-testid="Voltar"
                        onClick={(e) => {handleTemasClick(null, null, e)}} key="Voltar" data-id="Voltar"
                        value="Voltar">Voltar</button>

           </div>

        </div>

      </div>

    )

  }else {
    return (<br />);
  }

}
export default MsgRetorno;
