function consultarApi(_tribunal, pTema, pergunta) {

    var mensagemEnvio = { 
                            mensagem: { tipo: 'texto', conteudo: pergunta }, 
                            tema: pTema.tema ,
                            tribunal: _tribunal
                        }

    var payload = {
        method: 'POST', 
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(mensagemEnvio)
    };

    return new Promise((resolve, reject) => {

        if (!pTema || pTema.tema === '') {
            reject({ msg: process.env.REACT_APP_MSG_SELECAO_TEMA_OBRIGATORIO });
        }

        fetch(process.env.REACT_APP_SERVER_URL + pTema.endPoint, payload)
            .then(async response => {

                const data = await response.json();
                if (!response.ok) {
                    reject(data);
                } else {
                    resolve(data);
                }

            })
            .catch((error) => {
                reject({ msg: process.env.REACT_MSG_ERRO_GERAL });
            });
    })
}


function registrarConversa(_tribunal, mensagens, ptema, status) {

    const payload = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            mensagem: { tipo: 'texto', conteudo: mensagens },
            tema: ptema.tema,
            sucessoAtendimento: status,
            tribunal: _tribunal
        })
    };

    return new Promise((resolve, reject) => {

        fetch(process.env.REACT_APP_SERVER_URL + 'conversas/salvar', payload)
            .then(async response => {
                const data = await response.json();
                if (!response.ok) {
                    reject({ msg: 'Não foi possível registrar o atendimento. Erro: ' + response.status })
                } else {
                    resolve(data);
                }

            })
            .catch(error => {
                reject({ msg: process.env.REACT_MSG_ERRO_GERAL });
            });
    })
}

async function obterDadosTribunal(_tribunal) {

    const payload = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    return new Promise((resolve, reject) => {

        fetch(process.env.REACT_APP_SERVER_URL +'orientacoesGerais/obterDadosTribunal/'+_tribunal, payload)
            .then(async response => {
                const dados = await response.json();
                
                if (!response.ok) {
                    console.log(dados);
                    reject(
                        { 
                            msg: "Endereço "+ process.env.REACT_APP_SERVER_URL +"orientacoesGerais/obterDadosTribunal não disponível. Código do erro: " + response.status 
                        }
                        )
                } else {
                    resolve(dados);
                }

            })
            .catch(error => {
                reject({ msg: process.env.REACT_MSG_ERRO_GERAL });
            });
    })

}

const api = {
    consultarApi: consultarApi,
    obterDadosTribunal: obterDadosTribunal,
    registrarConversa: registrarConversa
}

export default api;
