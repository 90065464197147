import React, { useContext } from "react";
import { AppContext } from "../../ChatBot";

function MsgListarTemas(props) {

  const { handleMouseOut, handleMouseOver, tema, handleTemasClick } = useContext(AppContext);

  const temas = [];


  if (props.dadosMensagem.data) {

    props.dadosMensagem.data.temas.forEach((tema) => {
      var temaFormatado = tema._id.replaceAll('_', ' ').replace('IA ', '');

      temas.push(

        <p title={temaFormatado+' - '+ tema.qtd}
              onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}> {temaFormatado+' - '+ tema.qtd}</p>

      );

    });


    return (
      <div className="received-chats">
        <div className="received-msg">
          <div className="received-msg-inbox">
            <br/>
            <div>
            {
              temas
            }
            </div>
             <span className="time" title={props.dadosMensagem.dataHoraMsg}
              onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>{props.dadosMensagem.dataHoraMsg}</span>
            <br/>
            <div className="btn-group-horizontal">
              {
                tema.subTemas.map((subTema, index) =>
                (<button type="button" alt={subTema.tema} title={subTema.tema}
                  className="btn btn-outline-info" onMouseOut={handleMouseOut}
                  onMouseOver={handleMouseOver} onFocus={handleMouseOver} data-testid={subTema.tema}
                  onClick={(e) => { handleTemasClick(subTema, 'subTema', e) }} key={index} data-id={subTema.tema}
                  value={subTema.tema}>{subTema.tema}</button>)
                )

              }
            </div>
            <br/>
            <button type="button" alt="Voltar" title="Voltar"
                        className="btn btn-outline-info" onMouseOut={handleMouseOut} 
                        onMouseOver={handleMouseOver} onFocus={handleMouseOver} data-testid="Voltar"
                        onClick={(e) => {handleTemasClick(null, 'tema', e)}} key="Voltar" data-id="Voltar"
                        value="Voltar">Voltar</button>
          </div>
        </div>
      </div>
    )
  } else {
    return (

      <div className="received-chats">
        <div className="received-msg">
          <div className="received-msg-inbox">

            <p title={props.dadosMensagem.msg} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
              {props.dadosMensagem.msg}
            </p>
            <span className="time" title={props.dadosMensagem.dataHoraMsg} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>{props.dadosMensagem.dataHoraMsg}</span>

          </div>
        </div>
      </div>
    )

  }


}
export default MsgListarTemas;
