import speech from './speechEvents';
import utils from './utils';
import api from '../api/api';


function eventoOnKeyPress(addMensagemTexto, addMensagemObjeto, serviceDispatcher, addMensagemTextoSpinner, setInputValue, tema, removerUltimaMensagem ) {
    return (e) => {

        if (e.key === 'Enter') {
            if (e.target.value !== '') {

                if(tema && tema.searchComponent){
                    var ultimaMensagem = removerUltimaMensagem();
                    addMensagemTexto("MsgRetorno", tema.mensagemSelecaoObrigatoria);
                    addMensagemObjeto(ultimaMensagem.tipo, ultimaMensagem.data);
                    
               }else{

                addMensagemTexto("MsgEnvio", e.target.value);
                addMensagemTextoSpinner("MsgRetorno", process.env.REACT_APP_MSG_AGUARDE);
                serviceDispatcher(e.target.value);
                e.target.value = '';
                setInputValue('');

               }

            }

        }
    };
}

function eventoOnKeyDown(setCtrlPressionado, ctrlPressionado, vozAtiva, handleVoice) {
    return (e) => {

        if (e.which === 17) {
            setCtrlPressionado(true);
        } else {
            if (ctrlPressionado) {

                //- 65 - A 
                if (e.which === 65 && !vozAtiva) {
                    handleVoice();
                }

                //- 73 - I
                if (e.which === 73 && vozAtiva) {
                    handleVoice();
                }

                if (e.which === 77) {
                    speech.ouvir();
                }
                setCtrlPressionado(false);
            }

        }

    };
}

function enviarPergunta(textInput, addMensagemTexto, serviceDispatcher, addMensagemTextoSpinner, removerUltimaMensagem, addMensagemObjeto, tema) {
    return (e) => {

        if (textInput.current.value !== '') {


            if(tema && tema.searchComponent){
                var ultimaMensagem = removerUltimaMensagem();
                addMensagemTexto("MsgRetorno", tema.mensagemSelecaoObrigatoria);
                addMensagemObjeto(ultimaMensagem.tipo, ultimaMensagem.data);
                
           }else{

                addMensagemTexto("MsgEnvio", textInput.current.value);
                addMensagemTextoSpinner("MsgRetorno", process.env.REACT_APP_MSG_AGUARDE);
                serviceDispatcher(textInput.current.value);
                textInput.current.value = "";
           }
        }

        textInput.current.focus();

    };
}

function escolherTema(tribunal, setTema, setSubTema, addMensagemTexto, tema, addMensagemObjeto, setMensagens, mensagens, textInput) {

    return (pTema, tipo, e) => {

      if(pTema && pTema.searchComponent && 
            (pTema.searchComponent === "MsgDistribuicao" || 
             pTema.searchComponent === "MsgContatoUnidades" || 
             pTema.searchComponent === "MsgBuscaGenerica" )){
                
            setTema(pTema);
            addMensagemTexto("MsgEnvio", pTema.tema);
            addMensagemTexto("MsgRetorno", pTema.mensagemBoasVindas);
            addMensagemObjeto(pTema.componenteRenderizacao, "");

      }else 
        if(pTema && pTema.pesquisarAoClicar && pTema.pesquisarAoClicar==="sim"){
            
              api.consultarApi(tribunal, pTema, "").then(data => {
                addMensagemObjeto(pTema.componenteRenderizacao, data);
              }).catch((error) => {
                addMensagemTexto("MsgRetorno", error.msg);
              });
        }else 
        if (pTema && pTema.tema !== '') {
            if (tipo === 'tema') {
                setTema(pTema);
            } else {
                setSubTema(pTema);
            }

            addMensagemTexto("MsgEnvio", pTema.tema);
            addMensagemTexto("MsgRetorno", pTema.mensagemBoasVindas);

            if (pTema.subTemas && pTema.subTemas.length > 0) {
                addMensagemObjeto("MsgRetorno", pTema.subTemas);
            }

            setMensagens([...mensagens]);

        } else {

            iniciarAtendimento(setTema, setSubTema, setMensagens);

        }

        textInput.current.focus();
    };
}

function escolherPredicao(addMensagemTexto, serviceDispatcher, textInput) {
    return (e) => {

        addMensagemTexto("MsgEnvio", e.currentTarget.dataset.id);
        serviceDispatcher(e.currentTarget.dataset.id);
        textInput.current.focus();

    };
}

function iniciarAtendimento(setTema, setSubTema, setMensagens) {
    setTema(null);
    setSubTema(null);
    setMensagens([
        { tipo: "MsgInicio", msg: process.env.REACT_APP_MSG_BOAS_VINDAS, dataHoraMsg: utils.dataHoraAtual() }
    ]);
}

function handleEncerramentoClick(dadosTribunal, setMensagens, mensagens, tema, textInput) {

    return (e) => {

        var msgRetono = "";
        var msgStatus = "";
        if (e.currentTarget.dataset.id === 'Sim') {
            msgStatus = "SIM";
            msgRetono = dadosTribunal.MSG_RESPOSTA_AVALIACAO_SIM;
        }else 
        if (e.currentTarget.dataset.id === 'Não') {
            msgStatus = "NÃO";
            msgRetono = dadosTribunal.MSG_RESPOSTA_AVALIACAO_NAO;
        }
        api.registrarConversa(dadosTribunal, mensagens, tema, msgStatus).then(data => {
            setMensagens([...mensagens,
                { tipo: "MsgEnvio", msg: msgStatus, dataHoraMsg: utils.dataHoraAtual() },
                { tipo: "MsgRetorno", msg: msgRetono, dataHoraMsg: utils.dataHoraAtual() },
                { tipo: "MsgRetorno", msg: data.msg, dataHoraMsg: utils.dataHoraAtual() },
                { tipo: "MsgInicio", msg: "Escolha um tema na lista abaixo para realizar novo atendimento.", dataHoraMsg: utils.dataHoraAtual() }
            ]);

        }, error => {
            setMensagens([...mensagens,
                { tipo: "MsgEnvio", msg: msgStatus, dataHoraMsg: utils.dataHoraAtual() },
                { tipo: "MsgRetorno", msg: msgRetono, dataHoraMsg: utils.dataHoraAtual() },
                { tipo: "MsgRetorno", msg: error.msg, dataHoraMsg: utils.dataHoraAtual() },
                { tipo: "MsgInicio", msg: "Escolha um tema na lista abaixo para realizar novo atendimento.", dataHoraMsg: utils.dataHoraAtual() }
            ]);
        });
        textInput.current.focus();

    };
}


function obterRespostaAssistente(tribunal, tema, addMensagemObjeto, addMensagemTexto, subTema, setLoadingSpinner) {
    
    
    return (texto) => {
      setLoadingSpinner(true);
      if (tema && tema.endPoint) {

        api.consultarApi(tribunal, tema, texto).then(data => {
            
            if(data.status_code === 500){
                              
                addMensagemTexto('MsgRetorno', 'Ocorreu um erro ao tentar obter uma resposta sobre o tema '+ tema.descricao + '. A equipe técnica será notificada. Tente utilizar um outro tema. ');

                setLoadingSpinner(false);
            }else{
                addMensagemObjeto(tema.componenteRenderizacao, data);
                setLoadingSpinner(false);
            }
  
        }).catch((error) => {
          addMensagemTexto("MsgRetorno", error.msg);
          setLoadingSpinner(false);
        });
  
      } else {
  
        if (subTema) {
  
          api.consultarApi(tribunal, subTema, texto).then(data => {
            
            if(data.status_code===500){
                
                addMensagemTexto('MsgRetorno', 'Ocorreu um erro ao tentar obter uma resposta sobre o tema '+ subTema.descricao + '. A equipe técnica será notificada. Tente utilizar um outro tema. ');

                setLoadingSpinner(false);
            }else{
                
                addMensagemObjeto(subTema.componenteRenderizacao, data);
                setLoadingSpinner(false);
            }
            

          }).catch((error) => {
            
            addMensagemTexto("MsgRetorno", error.msg);
            setLoadingSpinner(false);
          });
  
        } else {
            setLoadingSpinner(false);
            if(tema && tema.tema === "Distribuição"){
                addMensagemTexto("MsgRetorno", process.env.REACT_APP_MSG_SELECAO_MUNICIPIO_OBRIGATORIO);
            }else{
                addMensagemTexto("MsgRetorno", process.env.REACT_APP_MSG_SELECAO_ITEM_OBRIGATORIO);
            }
        }
      }
    };
  }



const events = {
    eventoOnKeyPress: eventoOnKeyPress,
    eventoOnKeyDown: eventoOnKeyDown,
    enviarPergunta: enviarPergunta,
    escolherTema: escolherTema,
    escolherPredicao: escolherPredicao,
    handleEncerramentoClick:handleEncerramentoClick,
    obterRespostaAssistente:obterRespostaAssistente
}

export default events;